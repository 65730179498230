<template>
	<v-navigation-drawer
			floating
			:stateless="$vuetify.breakpoint.width >= 600"
			color="transparent"
			v-model="drawer"
			app
			clipped
			overlay-opacity="0.6"
			:width="240"
	>
		<div class="fill-height">
			<v-row class="mx-0" style="height: 48px;" align="center">
				<v-col class="pb-0">
					<div class="sub-title-2 font-weight-bold" :class="$vuetify.breakpoint.width >= 600 ? 'black2white--text':'white--text'">{{ $tc('global.name.filters', 2) }}</div>
				</v-col>
			</v-row>
			<v-row class="mx-0" style="height: calc(100% - 70px - 22px);">
				<v-col class="fill-height pr-2 pb-1">
					<div :class="$store.getters['style/classes'].container" class="fill-height" style=" overflow-y: auto; height: calc(100% - 8px)">
						<FieldGroup
								v-model="filters"
								:pevents="pevents"
								layout="filters"
								:fields="fieldsWithNestedCategories"
								:split="1"
								class="pb-4"
								v-if="fields"
						/>
					</div>
				</v-col>
			</v-row>
			<v-row class="mx-0">
				<v-col cols="6" class="pt-0 pr-2">
					<Button :label="$t('global.action.clear')" color="secondary" :click="() => clearFilters()" :block="true" />
				</v-col>
				<v-col cols="6" class="pt-0 pl-2 pr-2">
					<Button :label="$t('global.action.validate')" color="primary" :click="() => applyFilters(true)" :block="true" />
				</v-col>
			</v-row>
		</div>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "SideBarLeft",
		props: ['pevents'],
		data() {
			return {
				fields: {},
				filters: {},
				exclude: ['description', 'is_gift', 'marketplace', 'parent', 'payment', 'tax', 'image', 'workflow', 'status'],
				fieldsWithNestedCategories : {},
			}
		},
		components: {
			FieldGroup: () => import('@/components/FieldGroup.vue'),
			Button: () => import('@/components/ui/Button.vue')
		},
		computed: {
			drawer: {
				get(){
					return this.$store.getters['marketplace/drawer']
				},
				set(val) {
					this.$store.commit('marketplace/CHANGE_DRAWER_VAL', val)
				}
			}
		},
		methods: {
			initFilters(del){
				this.filters = {}

				let truc =  this.$route.query['page_size'] ? this.$route.query['page'] ? 2 : 1 : 0


				if(Object.keys(this.$route.query).length > truc && !del){
					for(let key in this.fields){
						if(this.fields[key].type === 'select' || this.fields[key].type === 'multiselect'){
							let obj = {}

							if(typeof this.fields[key].choices !== 'undefined') {
								for (let cle of this.fields[key].choices) {
									this.$set(obj, cle.id, false)
								}
							}

							this.$set(this.filters, key, obj)
						}
						else if(this.fields[key].type === 'float' || this.fields[key].type === 'integer'){
							this.$set(this.filters, key,{min: '', max: ''})
							
						}
						else {
							this.$set(this.filters, key, "")
						}
					}
					for(let key in this.$route.query) {
						if (key !== 'page' && key !=='page_size'){
							if (key.endsWith('_min')){
								if (this.fields[key.replace('_min','')].type === 'float' || this.fields[key.replace('_min','')].type === 'integer')
									this.$set(this.filters[key.replace('_min','')], 'min', this.$route.query[key] )
							}
							else if (key.endsWith('_max')){
								if (this.fields[key.replace('_max','')].type === 'float' || this.fields[key.replace('_min','')].type === 'integer')
									this.$set(this.filters[key.replace('_max','')], 'max', this.$route.query[key] )
							}
							else if (this.fields[key].type === 'multiselect' || this.fields[key].type === 'select') {
								this.$set(this.filters, key, {})
								
								if (Array.isArray(this.$route.query[key])) {
									for (let j of this.$route.query[key]) {
										this.$set(this.filters[key], Number.parseInt(j), true)
									}
								} else {
									this.$set(this.filters[key], Number.parseInt(this.$route.query[key]), true)
								}
							}
							else {
								this.$set(this.filters, key, this.$route.query[key])
							}
						}
					}
				}
				else {
					for(let key in this.fields){
						if(this.fields[key].type === 'select' || this.fields[key].type === 'multiselect'){
							let obj = {}

							if(typeof this.fields[key].choices !== 'undefined') {
								for (let cle of this.fields[key].choices) {
									this.$set(obj, cle.id, false)
								}
							}

							this.$set(this.filters, key, obj)
						}
						else if(this.fields[key].type === 'float' || this.fields[key].type === 'integer'){
							this.$set(this.filters, key ,{min: '', max: ''})
							
						}
						else {
							this.$set(this.filters, key, "")
						}
					}
				}
			},
			applyFilters(click){
				let query = {}

				for(let key in this.filters){
					if(!this.filters[key]){
						this.$delete(this.filters, key)
					}
					else {
						if (this.fields[key].type === "float"){
							if (this.filters[key].min !== "" ){
								query[key+'_min']= this.filters[key].min
							}
							if (this.filters[key].max !== ""){
								query[key+'_max']= this.filters[key].max
							}
						}
						else if (typeof this.filters[key] === "object"){
							query[key] = Object.keys(this.filters[key]).filter(k => this.filters[key][k] === true)
						}
						else {
							query[key] = this.filters[key]
						}
					}
				}

				if(click){
					query['page'] = 1
					query['page_size'] = this.$route.query['page_size'] ? this.$route.query['page_size'] : 16
					this.$router.push({query}).catch(() => {})
				}
			},
			clearFilters(){
				this.initFilters(true)
				this.applyFilters(true)
			},
			initCategories(){
				this.fieldsWithNestedCategories = JSON.parse(JSON.stringify(this.fields))

				this.fieldsWithNestedCategories.category.choices.forEach(c => {
					if (c.parent !== null && typeof c.parent !== 'undefined'){
						let parent = this.fieldsWithNestedCategories.category.choices.find(cate => cate.id === c.parent.id)

						if (!parent.child){
							this.$set(parent, 'child', [])
							parent.child.push(c)
						}
						else if (!parent.child.find(cate => cate.id === c.id)){
							parent.child.push(c)
						}
					}
				})

				let res = []

				for ( let i = 0; i < this.fields.category.choices.length; i++){
					if (this.fieldsWithNestedCategories.category.choices[i].parent === null){
						res.push(this.fieldsWithNestedCategories.category.choices[i])
					}	
				}

				this.fieldsWithNestedCategories.category.choices = res
			},
			setUpHooks(){
				this.pevents.wait('filters', () => {
                    this.pevents.notify('give-filters', this.fields.category.choices, this.fields.provider.choices)
                })

				this.pevents.wait('update-app', () => {
					this.$wsc.getOptions(this.$route.params, {}, 'product', success => {
						let res = {}

						Object.keys(success.fields).forEach(e => {
							if(!this.exclude.includes(e) && !success.fields[e].read_only){
								res[e] = success.fields[e]
							}
						})

						this.fields = res
						this.pevents.notify('give-filters', this.fields.category.choices , this.fields.provider.choices)
						this.initFilters()
						this.applyFilters()

					}, fail => {
					})
				})
			}
		},
		created() {
			this.setUpHooks()
			
			this.$wsc.getOptions(this.$route.params, {}, 'product', success => {
				let res = {}

				Object.keys(success.fields).forEach(e => {
					if(!this.exclude.includes(e) && !success.fields[e].read_only){
						res[e] = success.fields[e]
					}
				})

				this.fields = res
				this.pevents.notify('give-filters', this.fields.category.choices , this.fields.provider.choices)
				this.initCategories()
				this.initFilters()
				this.applyFilters()
			}, fail => {
			})
		}
	}
</script>

<style scoped>

</style>
